import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthorizationService } from '@clients-nside-io/shared/auth';
import { ApiGatewayAuthService } from './api-gateway-auth.service';
import { switchMap } from 'rxjs/operators';
import { IAPIResponse } from '../models/api-response';

@Injectable({
  providedIn: 'root',
})
export class JwtRefreshService implements HttpInterceptor {
  constructor(
    private authService: AuthorizationService,
    private apiGateWayAuthService: ApiGatewayAuthService
  ) {}
  
  // this function gonna execute when any http request from the app is made
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const nSideIdJwt = this.authService.getToken();

    // if the request url is not authentication endpoint
    if (
      !req.url.includes('/api/auth') &&
      !req.url.includes('nside.io') &&
      !req.url.includes('identity')
    ) {
      // calling GCP endpoint for a new access token to Google Cloud API Gateway
      return this.apiGateWayAuthService
        .getGoogleCloudAccessToken(nSideIdJwt)
        .pipe(
          switchMap((resp: IAPIResponse<string>) => {
            // add GCP Jwt to the header of those request
            const transformedReq = req.clone({
              headers: req.headers.set('Authorization', `Bearer ${resp.data}`),
            });
            return next.handle(transformedReq);
          })
        );
    } else {
      return next.handle(req);
    }
  }
}
