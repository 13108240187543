import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { MainComponent } from './views/main/main.component';
import { AppRoutingModule } from './modules/routing';
import { MaterialModule } from './modules/material';
import { BuildingListComponent } from './views/components/building-list/building-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { FloorListComponent } from './views/components/floor-list/floor-list.component';
import { CameraListComponent } from './views/components/camera-list/camera-list.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapComponent } from './views/components/map/map.component';
import {
  CameraWallComponent,
  SafePipe,
} from './views/components/camera-wall/camera-wall.component';
import { CameraDetailComponent } from './views/components/camera-wall/camera-detail/camera-detail.component';
import { ErrorComponent } from './views/error/error.component';

import {
  AuthInterceptor,
  AuthModule,
  OidcConfigService,
} from 'angular-auth-oidc-client';
import { environment } from '../environments/environment';
import { RedirectComponent } from './views/redirect/redirect.component';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { JwtRefreshService } from './services/jwt-refresh.service';

//set up authentication function from environment config.
export function configureAuth(
  oidcConfigService: OidcConfigService
): () => Promise<any> {
  return () => oidcConfigService.withConfig(environment.identityConfig);
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    BuildingListComponent,
    FloorListComponent,
    CameraListComponent,
    MapComponent,
    CameraWallComponent,
    SafePipe,
    CameraDetailComponent,
    ErrorComponent,
    RedirectComponent,
    UnauthorizedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    AuthModule.forRoot(),
  ],
  providers: [
    //set up providers for security service
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtRefreshService,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
