import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRoutes } from '../models/api-routes';
import { IAPIResponse } from '../models/api-response';
import { Observable } from 'rxjs';
import { ICamera } from '../models/camera';

@Injectable({
  providedIn: 'root',
})
export class CameraService {
  constructor(private http: HttpClient) {}

  public getAllCamerasBelongToBuildingFloor(
    buildingId: number,
    floorId: number
  ): Observable<IAPIResponse<ICamera[]>> {
    return this.http.get<IAPIResponse<ICamera[]>>(
      ApiRoutes.cameraList + buildingId + '/' + floorId
    );
  }

  public getCameraLiveStreamURLByCameraSerialNumber(
    cameraSN: string
  ): Observable<IAPIResponse<string>> {
    return this.http.get<IAPIResponse<string>>(
      ApiRoutes.cameraLiveStreamURLBySN + cameraSN
    );
  }

  public getCameraLiveStreamHistorySupportedURLByCameraSerialNumber(
    cameraSN: string
  ): Observable<IAPIResponse<string>> {
    return this.http.get<IAPIResponse<string>>(
      ApiRoutes.cameraLiveStreamHistorySupportedBySN + cameraSN
    );
  }
}
