import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from '../views/main/main.component';
import { ErrorComponent } from '../views/error/error.component';
import { UnauthorizedComponent } from '../views/unauthorized/unauthorized.component';
import { RedirectComponent } from '../views/redirect/redirect.component';
import { AuthorizationGuard } from '@clients-nside-io/shared/auth';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'building/:buildingId/floor/:floorId',
    component: MainComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'building/:buildingId',
    component: MainComponent,
    canActivate: [AuthorizationGuard]
  },
  {
    path: 'redirect',
    component: RedirectComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
