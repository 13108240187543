import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthorizationService } from '@clients-nside-io/shared/auth';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent {
  constructor(
    private router: Router,
    private authService: AuthorizationService
  ) {}


  isLoggedIn$ = this.authService.checkAuth().pipe(
    map((loggedIn) => {
      if (loggedIn && localStorage.getItem('buildingId') && localStorage.getItem('floorId')) {
        this.router.navigate([
          `building/${localStorage.getItem('buildingId')}/floor/${localStorage.getItem('floorId')}`,
        ]);
        return true;
      } else if (loggedIn && localStorage.getItem('buildingId')) {
        this.router.navigate([
          `building/${localStorage.getItem('buildingId')}`,
        ]);
        return true;
      } else {
        this.router.navigate(['unauthorized']);
        return false;
      }
    })
  );
}
