import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
} from '@angular/core';
import { BuildingService } from '../../../services/building.service';
import { IBuilding } from '../../../models/building';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelectChange } from '@angular/material/select';
import { DebugUtils } from '@clients-nside-io/shared/util';

@Component({
  selector: 'clients-nside-io-building-list',
  templateUrl: './building-list.component.html',
  styleUrls: ['./building-list.component.scss'],
})
export class BuildingListComponent implements OnInit, OnDestroy {
  public buildingsList: IBuilding[] = [];
  public isLoading: boolean = false;
  public buildingFilterCtrl: FormControl = new FormControl();

  /** building list form controller */
  public buildingCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredBuildings: ReplaySubject<IBuilding[]> = new ReplaySubject<
    IBuilding[]
  >(1);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  /** Selected building object from building selection */
  public selectedBuilding: IBuilding | null = null;

  /** Output building data event emitter */
  @Output() buildingSelectEvent = new EventEmitter<IBuilding>();

  constructor(private buildingService: BuildingService, private debug: DebugUtils) {}

  ngOnInit(): void {
    this.loadBuildingsList();
    // set action handler for building searching bar
    this.buildingFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBuildings();
      });
  }



  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  private loadBuildingsList() {
    this.isLoading = true;
    this.buildingService.getBuildingList().subscribe(
      (response) => {
        if (response.data) {
          this.buildingsList = response.data;

          // set initial selection from localStorage (if exist)
          const saveSelectedBuilding: IBuilding = JSON.parse(
            localStorage.getItem('live-current-selected-building')!!
          );

          if (saveSelectedBuilding) {
            for (let building of this.buildingsList) {
              if (building.id === saveSelectedBuilding.id) {
                this.buildingCtrl.setValue(building);
                this.buildingSelectEvent.emit(building);
                break;
              }
            }
          }
          

          // load initial buildings list, init the view.
          this.filteredBuildings.next(this.buildingsList?.slice());

          this.isLoading = false;
        }
      },
      (err) => {
        this.debug.log(err);
      }
    );
  }

  private filterBuildings() {
    if (!this.buildingsList) {
      return;
    }
    // get the search keyword
    let inputKeyword: string = this.buildingFilterCtrl.value
      ? this.buildingFilterCtrl.value
      : '';

    if (!inputKeyword) {
      this.filteredBuildings.next(this.buildingsList.slice());
      return;
    } else {
      inputKeyword = inputKeyword.toLowerCase();
    }
    // filter the buildings
    this.filteredBuildings.next(
      this.buildingsList.filter((building: IBuilding) =>
        this.buildingFilterConstraint(building, inputKeyword)
      )
    );
  }

  private buildingFilterConstraint(building: IBuilding, inputKeyword: string) {
    if (!building) return false;
    if (!building.name) return false;

    if (building.name.toLowerCase().indexOf(inputKeyword) > -1) {
      return true;
    }

    return false;
  }

  public onSelectedBuildingChange(event: MatSelectChange): void {
    if (event.value) {
      this.buildingSelectEvent.emit(event.value);
    }
  }
}
