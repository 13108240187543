import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRoutes } from '../models/api-routes';
import { IAPIResponse } from '../models/api-response';
import { Observable } from 'rxjs';
import { IFloor } from '../models/floor';
import { IRoom } from '../models/room';

@Injectable({
  providedIn: 'root',
})
export class FloorService {
  constructor(private http: HttpClient) {}

  public getAllFloorsBelongToBuildingId(
    buildingId: number
  ): Observable<IAPIResponse<IFloor[]>> {
    return this.http.get<IAPIResponse<IFloor[]>>(
      ApiRoutes.floorList + buildingId,
      {
        headers: {
          Authorization:
            'Bearer ' + localStorage.getItem('x-gcp-apigateway-jwt'),
        },
      }
    );
  }

  public getFloorPlanBelongToFloorIdOfBuildingId(
    buildingId: number,
    floorId: number
  ): Observable<IAPIResponse<IRoom[]>> {
    return this.http.get<IAPIResponse<IRoom[]>>(
      ApiRoutes.floorPlan + buildingId + '/' + floorId
    );
  }
}
