<h1 mat-dialog-title class="">
  {{ targetCamera.platform_camera_name ? targetCamera.platform_camera_name : targetCamera.detail_info.camera_name }}
</h1>
<mat-spinner class="mt-2" [diameter]="50" *ngIf="targetCameraLiveStreamURL === ''"></mat-spinner>
<mat-dialog-content
  style="width: 100%; height: 100vh; width: 100%;height: 100vh;margin-right: 0px;margin-left: 0px;padding-left: 0px;padding-right: 0px;"
  *ngIf="targetCameraLiveStreamURL !== ''" class="d-flex justify-content-center">
  <iframe height="95%" loading="lazy" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""
    referrerpolicy="no-referrer" class="w-100 shadow-1-strong rounded-3" [src]="targetCameraLiveStreamURL | safe">
  </iframe>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="btn btn-danger">Close</button>
</mat-dialog-actions>