import { environment } from '../../environments/environment';

export const ApiRoutes = {
  auth: environment.apiBaseUrl + 'api/auth',
  buildingList: environment.apiBaseUrl + 'api/building/all',
  floorList: environment.apiBaseUrl + 'api/building/floor/',
  floorPlan: environment.apiBaseUrl + 'api/building/room/',
  cameraList: environment.apiBaseUrl + 'api/building/camera/',
  cameraLiveStreamURLBySN: environment.apiBaseUrl + 'api/camera/livestream/sn/',
  cameraLiveStreamHistorySupportedBySN: environment.apiBaseUrl + '/api/camera/livestream/historySupported/sn/',
  targetBuildingAndFloor: (buildingId: number, floorId: number) => {
    return (
      environment.apiBaseUrl + `api/building/${buildingId}/floor/${floorId}`
    );
  },
};
