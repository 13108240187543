<div
  class="row d-flex justify-content-center"
  style="padding-top: 5px; padding-bottom: 5%"
>
  <mat-card
    *ngIf="isLoadingCameraLiveStreamURLs"
    class="z-index-0 col-xl-3 col-md-6 col-sm-12"
  >
    <mat-card-header class="d-flex justify-content-center" style="height: 6vh">
      <mat-card-title>
        <mat-spinner class="mt-2" [diameter]="50"></mat-spinner>
      </mat-card-title>
    </mat-card-header>
  </mat-card>

  <mat-card
    class="col-xl-3 col-md-6 col-sm-12 card-wrapper border border-secondary"
    *ngFor="let camera of displayedCameraList; let i = index"
  >
    <mat-card-header style="height: 6vh">
      <mat-card-title>{{
        camera.platform_camera_name
          ? camera.platform_camera_name
          : camera.detail_info.camera_name
      }}</mat-card-title>
    </mat-card-header>
    <iframe
      width="100%"
      loading="lazy"
      height="250px"
      loading="lazy"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen=""
      referrerpolicy="no-referrer"
      class="shadow-1-strong rounded-3"
      [src]="camera.detail_info.livestream_url | safe"
    >
    </iframe>
    <mat-card-content style="height: 5vh" class="m-0">
      <p>
        <b>Located at:</b>
        {{
          camera.detail_info.address !== ''
            ? camera.detail_info.address
            : 'Unknown'
        }}
      </p>
    </mat-card-content>
    <mat-card-actions class="d-flex justify-content-center p-0">
      <button
        class="btn btn-success p-1 d-flex justify-content-center w-100"
        type="button"
        (click)="openCameraDetailClickHandler(camera)"
      >
        <mat-icon>remove_red_eye</mat-icon>
      </button>
      <button
        class="btn btn-danger p-1 d-flex justify-content-center w-100"
        (click)="ejectCameraByIndexOnVideoWallArray(i)"
      >
        <mat-icon>tv_off</mat-icon>
      </button>
    </mat-card-actions>
  </mat-card>
</div>
