export interface User {
  sub: string;
  role: string[];
  user_data: string[];
  preferred_username: string;
  name: string;
  emailaddress: string;
  mobilephone: string;
  givenname: string;
  surname: string;
}

export interface IUserDataInfo {
  Name: string;
  Value: any;
}
