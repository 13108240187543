<div class="">
  <div *ngIf="!isLoading">
    <mat-form-field appearance="fill" style="width: 100%">
      <mat-label>Select a floor...</mat-label>
      <mat-select
        [(value)]="selectedFloor"
        (selectionChange)="onSelectedFloorChange($event)"
      >
        <mat-option *ngFor="let floor of floorsList" [value]="floor">
          {{ floor.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="isLoading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</div>
