<div>
  <header class="fixed-top">
    <nav
      class="navbar navbar-light p-0"
      style="z-index: 10; background-color: #ffffff"
    >
      <!-- <span>
    <button class="btn" (click)="drawer.toggle()">
      <mat-icon style="color: white" class="" aria-hidden="false"
        >view_list</mat-icon
      >
    </button>
    <a class="navbar-brand" href="https://nside.io">
      <img src="../../../assets/img/logo_small.png" alt="" srcset="" />
    </a>
    <div class="ms-1 me-1 mt-1">
      <p >{{currentBuilding?.name}}</p>
      <p >{{currentFloor?.name}}</p>
    </div>
  </span> -->

      <div class="container-fluid">
        <a
          class="navbar-brand d-none d-md-block d-lg-block d-xl-block"
          href="https://nside.io"
        >
          <img
            src="../../../assets/img/nSideLive.png"
            width="220"
            alt=""
            srcset=""
          />
        </a>
        <h5
          class="mt-3"
          style="font-family: Oswald; font-size: 22px; color: #5a5a5a"
          *ngIf="currentBuilding && currentFloor"
        >
          {{ currentBuilding.name }} - {{ currentFloor.name }}
        </h5>
        <button
          class="navbar-toggler burger-button"
          type="button"
          (click)="drawer.toggle()"
        >
          <mat-icon style="color: #f25d22" class="" aria-hidden="false"
            >view_list</mat-icon
          >
        </button>
      </div>
    </nav>
  </header>

  <mat-drawer-container
    class="example-container"
    style="font-family: Raleway"
    autosize
  >
    <mat-drawer
      #drawer
      opened
      class="example-sidenav"
      fixedInViewport="true"
      mode="side"
      style="background-color: #cccccc"
    >
      <div class="ms-1 me-1 mt-2">
        <mat-spinner
          class="mt-2"
          [diameter]="50"
          *ngIf="isLoadingBuildingAndFloor"
        ></mat-spinner>
        <clients-nside-io-map
          [current-building]="currentBuilding"
          [current-floor]="currentFloor"
          [current-cameras-list]="currentCamerasList"
          [current-selected-camera-list]="currentSelectedCamerasList"
          (selectedCamerasListEvent)="
            updateCurrentSelectedCamerasListFromMap($event)
          "
        ></clients-nside-io-map>

        <!-- <clients-nside-io-building-list
          (buildingSelectEvent)="updateCurrentBuilding($event)"
        >
        </clients-nside-io-building-list> -->

        <clients-nside-io-floor-list
          [current-building]="currentBuilding"
          [current-floor]="currentFloor"
          (floorSelectEvent)="updateCurrentFloor($event)"
          *ngIf="currentBuilding"
        >
        </clients-nside-io-floor-list>

        <clients-nside-io-camera-list
          [current-building]="currentBuilding"
          [current-floor]="currentFloor"
          (camerasListLoadedEvent)="updateCurrentCamerasList($event)"
          (selectedCamerasListEvent)="updateCurrentSelectedCamerasList($event)"
          [current-selected-camera-list-from-map]="currentSelectedCamerasList"
          [is-loading-camera-livestream-url]="isLoadingCameraLiveStreamUrl"
          *ngIf="currentBuilding && currentFloor"
        >
        </clients-nside-io-camera-list>
        <button
          *ngIf="currentBuilding && currentFloor"
          class="btn btn-danger w-100 mt-2"
          (click)="logOut()"
        >
          <b>Log Out</b>
        </button>
      </div>
    </mat-drawer>

    <div
      class="example-sidenav-content me-1 ms-1 mt-1 mb-1"
      style="background-color: #ffffff"
    >
      <div class="container-fluid">
        <clients-nside-io-camera-wall
          *ngIf="currentSelectedCamerasList!!.length > 0"
          [current-selected-camera-list-from-map]="currentSelectedCamerasList"
          (selectedCamerasListEvent)="
            updateCurrentSelectedCamerasListFromCamerWall($event)
          "
          (isLoadingCameraLiveStreamURLsEvent)="
            updateIsLoadingCameraLiveStreamURL($event)
          "
        ></clients-nside-io-camera-wall>
      </div>
      <div
        *ngIf="currentSelectedCamerasList!!.length === 0"
        class="d-flex justify-content-center p-5"
      >
        <img
          class="img-fluid mt-5"
          style="height: 40%; width: 40%"
          src="../../../assets/img/nSideLive.png"
          alt=""
          srcset=""
        />
      </div>
    </div>
  </mat-drawer-container>
  <footer class="fixed-bottom d-none d-md-block d-lg-block d-xl-block">
    <div
      style="
        text-align: left;
        color: white;
        background-color: #5a5a5a;
        padding-left: 10px;
      "
      class="text-center"
    >
      Copyright © 2015 - 2024 nSide™ | All Rights Reserved
    </div>
  </footer>
</div>
