import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRoutes } from '../models/api-routes';
import { IAPIResponse } from '../models/api-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiGatewayAuthService {
  constructor(private http: HttpClient) {}

  public getGoogleCloudAccessToken(
    nSideAccessToken: string
  ): Observable<IAPIResponse<string>> {
    return this.http.get<IAPIResponse<string>>(ApiRoutes.auth, {
      headers: {
        Authorization: 'Bearer ' + nSideAccessToken,
      },
    });
  }
}
