// Environment set up for Development.
export const environment = {
  production: false,
  apiBaseUrl: "https://dev-live-nside-io-api-gateway-45uwghws.uc.gateway.dev/",
  identityConfig: {
    stsServer: 'https://identity.staging.nside.io',
    redirectUrl: `${window.location.origin}`,
    postLogoutRedirectUri: 'https://staging.nside.io/Default.aspx',
    clientId: 'live.dev.nside.io',
    scope: 'api.staging.nside.io openid roles profile offline_access',
    responseType: 'code',
    silentRenew: true,
    silentRenewUrl: `${window.location.origin}/silent-renew.html`,
    useRefreshToken: true,
    renewTimeBeforeTokenExpiresInSeconds: 30,
  }
};

