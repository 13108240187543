declare var google: any;
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ViewChildren,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { IFloor } from '../../../models/floor';
import { IBuilding } from '../../../models/building';
import { ICamera } from '../../../models/camera';
import { IRoom } from '../../../models/room';
import { FloorService } from '../../../services/floor.service';
import { MapMarker } from '@angular/google-maps';
import { DebugUtils } from '@clients-nside-io/shared/util';

@Component({
  selector: 'clients-nside-io-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, OnChanges {
  // input current building and floor and cameras list
  @Input('current-building') currentBuilding: IBuilding | null = null;
  @Input('current-floor') currentFloor: IFloor | null = null;
  @Input('current-cameras-list') currentCamerasList: ICamera[] | null = null;

  // center of the map
  public center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };

  // options of the map
  public options: google.maps.MapOptions = {
    disableDefaultUI: true,
    fullscreenControl: false,
    zoomControl: true,
    keyboardShortcuts: false,
  };

  // polygon of rooms
  public rooms: IRoom[] = [];

  // icon style for deactive camera
  public deactiveCameraIconOptions: google.maps.MarkerOptions = {
    icon: {
      url: '../../../assets/img/deactive.png',
      scaledSize: new google.maps.Size(20, 20),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(10, 10),
    },
    clickable: true,
    optimized: true,
    zIndex: 50,
  };

  // icon style for active camera
  public activeCameraIconOptions: google.maps.MarkerOptions = {
    icon: {
      url: '../../../assets/img/active.png',
      scaledSize: new google.maps.Size(20, 20),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(10, 10),
    },
    clickable: true,
    optimized: true,
    zIndex: 100,
  };

  // array of active cameras inside original camera array
  public activeCamerasList: ICamera[] | null = [];

  // array of active cameras from MainComponent (or Camera List Component)
  @Input('current-selected-camera-list') currentInputSelectedCamerasList:
    | ICamera[]
    | null = [];

  public roomNameLabelOptions: google.maps.MarkerOptions = {
    visible: true,
    zIndex: 110,
    icon: {
      path: google.maps.SymbolPath.CIRCLE,
      scale: 0,
    },
  };

  // output active camera list event EventEmitter
  @Output() selectedCamerasListEvent = new EventEmitter<ICamera[]>();

  constructor(
    private floorService: FloorService,
    private debugUtil: DebugUtils
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentBuilding && !changes.currentBuilding.isFirstChange()) {
      this.center = {
        lat: changes.currentBuilding.currentValue.starting_point[0].lat,
        lng: changes.currentBuilding.currentValue.starting_point[0].lng,
      };

      if (this.currentBuilding?.id && this.currentFloor?.id) {
        this.floorService
          .getFloorPlanBelongToFloorIdOfBuildingId(
            this.currentBuilding?.id,
            this.currentFloor?.id
          )
          .subscribe(
            (response) => {
              if (response.code == 200) {
                this.rooms = response.data;
              }
            },
            (err) => {
              this.debugUtil.log(err);
            }
          );
      }
    } else if (changes.currentFloor && !changes.currentFloor.isFirstChange()) {
      if (this.currentBuilding?.id && this.currentFloor?.id) {
        this.floorService
          .getFloorPlanBelongToFloorIdOfBuildingId(
            this.currentBuilding?.id,
            this.currentFloor?.id
          )
          .subscribe(
            (response) => {
              if (response.code == 200) {
                this.rooms = response.data;
              }
            },
            (err) => {
              this.debugUtil.log(err);
            }
          );
      }
    } else if (
      changes.currentCamerasList &&
      !changes.currentCamerasList.isFirstChange()
    ) {
      this.debugUtil.log('From map component', this.currentCamerasList);
    } else if (
      changes.currentInputSelectedCamerasList &&
      !changes.currentInputSelectedCamerasList.isFirstChange()
    ) {
      this.activeCamerasList = this.currentInputSelectedCamerasList;

      // hover to newest clicked camera
      if (this.activeCamerasList && this.activeCamerasList.length > 0) {
        const newestAddedCamera: ICamera =
          this.activeCamerasList[this.activeCamerasList.length - 1];

        this.center = {
          lat: Number(newestAddedCamera.geo_data[0].lat),
          lng: Number(newestAddedCamera.geo_data[0].lng),
        };
      }
    }
  }

  // action handler when clicking deactive cameras
  public deactiveCameraClickHandler(
    clickedCamera: ICamera,
    indexInActiveArray: number
  ): void {
    if (this.activeCamerasList) {
      this.activeCamerasList.push(clickedCamera);
      // send the current active cameras list to Main Component
      this.selectedCamerasListEvent.emit(this.activeCamerasList);
    }
  }
  // action handler when clicking active cameras
  public activeCameraClickHandler(
    clickedCamera: ICamera,
    indexInActiveArray: number
  ) {
    if (this.activeCamerasList) {
      this.activeCamerasList.splice(indexInActiveArray, 1);

      // send the current active cameras list to Main Component
      this.selectedCamerasListEvent.emit(this.activeCamerasList);
    }
  }
}
