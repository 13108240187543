export interface IAddPropsBase<TData> {
  [propertyName: string]: TData;
  [propertyName: number]: TData;
}

export type IAddPropsDefault = IAddPropsBase<any>;

export type OneOrMore<TAny> = TAny | TAny[];

export interface IAssessDataForRoute {
  buildingId: number | string;
  action: string;
  assessCaseID: string;
  submissionId: string;
  formName: string;
  formVersion?: number | string | null | undefined;
  isOpeningForm: boolean;
}
