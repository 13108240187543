import { Injectable } from '@angular/core';
import { ApiRoutes } from '../models/api-routes';
import { IAPIResponse } from '../models/api-response';
import { IBuilding } from '../models/building';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  constructor(private http: HttpClient) {}

  // get list of buildings from API.
  public getBuildingList(): Observable<IAPIResponse<IBuilding[]>> {
    return this.http.get<IAPIResponse<IBuilding[]>>(ApiRoutes.buildingList);
  }

  // save list of buildings to local storage
  public saveBuildingsListToLocalStorage(buildingList: IBuilding[]): void {
    localStorage.setItem(
      'nside-live-buildings-list',
      JSON.stringify(buildingList)
    );
  }

  // load list of buildings from local storage
  public loadBuildingsListFromLocalStorage(): IBuilding[] {
    if (localStorage.getItem('nside-live-buildings-list')) {
      let buildingsListString: string | null =
        localStorage.getItem('nside-live-buildings-list') || '';
      let buildingsList = JSON.parse(buildingsListString);
      return buildingsList;
    } else {
      return [];
    }
  }
}
