import { Component, OnInit } from '@angular/core';
import { IBuilding } from '../../models/building';
import { IFloor } from '../../models/floor';
import { ICamera } from '../../models/camera';
import { ActivatedRoute } from '@angular/router';
import { BuildingAndFloorService } from '../../services/building-and-floor.service';
import { FloorService } from '../../services/floor.service';
import { Router } from '@angular/router';
import { AuthorizationService } from '@clients-nside-io/shared/auth';

@Component({
  selector: 'clients-nside-io-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  public currentBuilding: IBuilding | null = null;
  public currentFloor: IFloor | null = null;
  public currentCamerasList: ICamera[] | null = [];
  public currentSelectedCamerasList: ICamera[] | null = [];

  private paramBuildingId: number | string | null = null;
  private paramFloorId: number | string | null = null;

  public isLoadingBuildingAndFloor: boolean = false;

  public isLoadingCameraLiveStreamUrl: boolean = false;

  public isAuthenticatedFromNSideIdentity: boolean = false;

  constructor(
    private activedRoute: ActivatedRoute,
    private buildingAndFloorService: BuildingAndFloorService,
    private floorService: FloorService,
    private router: Router,
    private authService: AuthorizationService
  ) {}

  ngOnInit(): void {
    this.inputBuildingAndFloorFromParams();
  }

  // get current building from Building Component
  public updateCurrentBuilding(event: IBuilding) {
    this.currentBuilding = event;
    localStorage.setItem(
      'live-current-selected-building',
      JSON.stringify(this.currentBuilding)
    );
  }

  // get current floor from Floor Component
  public updateCurrentFloor(event: IFloor) {
    this.currentFloor = event;
  }

  // get current cameras list from CameraList Component
  public updateCurrentCamerasList(event: ICamera[]) {
    this.currentCamerasList = event;
  }

  // get current selected cameras list from CameraList Component
  public updateCurrentSelectedCamerasList(event: ICamera[]) {
    this.currentSelectedCamerasList = event;
  }

  // get current selected cameras list from CameraList Component
  public updateCurrentSelectedCamerasListFromMap(event: ICamera[]) {
    this.currentSelectedCamerasList = event;
    let tempArray = [...event];
    this.currentSelectedCamerasList = [];
    this.currentSelectedCamerasList = tempArray;
  }

  // get current selected cameras list from CameraList Component
  public updateCurrentSelectedCamerasListFromCamerWall(event: ICamera[]) {
    this.currentSelectedCamerasList = event;
    let tempArray = [...event];
    this.currentSelectedCamerasList = [];
    this.currentSelectedCamerasList = tempArray;
  }

  // get buildingId and floorId from parameter and getting
  public inputBuildingAndFloorFromParams() {
    this.paramBuildingId =
      this.activedRoute.snapshot.paramMap.get('buildingId');
    this.paramFloorId = this.activedRoute.snapshot.paramMap.get('floorId');

    if (this.paramBuildingId) {
      if (this.paramFloorId) {
        this.paramBuildingId = parseInt(this.paramBuildingId);
        this.paramFloorId = parseInt(this.paramFloorId);

        // show up the loading spinner
        this.isLoadingBuildingAndFloor = true;

        this.buildingAndFloorService
          .getTargetBuildingAndFloor(this.paramBuildingId, this.paramFloorId)
          .subscribe(
            (response) => {
              if (response.code == 200) {
                this.currentBuilding = response.data.targetBuilding;
                this.currentFloor = response.data.targetFloor;
                this.isLoadingBuildingAndFloor = false;
              } else {
                this.router.navigate(['error']);
              }
            },
            (error) => {
              this.router.navigate(['error']);
            }
          );
      } else {
        localStorage.removeItem("floorId");
        // if the user did not input floorId,
        // then get the first floor in the building list

        // call the floor service for getting the floor list belonged to this building
        this.floorService
          .getAllFloorsBelongToBuildingId(Number.parseInt(this.paramBuildingId))
          .subscribe(
            (response) => {
              if (response.code == 200) {
                // if the building has the floor list
                if (response.data.length > 0) {
                  // if the first floor of this list is existed
                  if (response.data[0].id) {
                    // grab the first floor of the list
                    this.paramFloorId = response.data[0].id;
                    // navigate to the correct route, which has buildingId and floorId
                    this.router.navigate([
                      `building/${this.paramBuildingId}/floor/${this.paramFloorId}`,
                    ]);
                  } else this.router.navigate(['error']);
                } else {
                  this.router.navigate(['error']);
                }
              } else {
                this.router.navigate(['error']);
              }
            },
            (error) => {
              this.router.navigate(['error']);
            }
          );
      }
    } else {
      // if there is no buidingId and floorId input
      // then check for the local storage for the last access
      if (
        localStorage.getItem('buildingId') &&
        localStorage.getItem('floorId')
      ) {
        this.router.navigate([
          `building/${localStorage.getItem(
            'buildingId'
          )}/floor/${localStorage.getItem('floorId')}`,
        ]);
      } else if (localStorage.getItem('buildingId')) {
        
        this.floorService
          .getAllFloorsBelongToBuildingId(Number.parseInt(localStorage.getItem('buildingId')!!))
          .subscribe(
            (response) => {
              if (response.code == 200) {
                // if the building has the floor list
                if (response.data.length > 0) {
                  // if the first floor of this list is existed
                  if (response.data[0].id) {
                    // grab the first floor of the list
                    this.paramFloorId = response.data[0].id;
                    // navigate to the correct route, which has buildingId and floorId
                    
                    this.router.navigate([
                      `building/${localStorage.getItem('buildingId')!!}/floor/${this.paramFloorId}`,
                    ]);
                  } else this.router.navigate(['error']);
                } else {
                  this.router.navigate(['error']);
                }
              } else {
                this.router.navigate(['error']);
              }
            },
            (error) => {
              this.router.navigate(['error']);
            }
          );
      }
      else {
        // else navigate to the error page.
        this.router.navigate(['error']);
      }
    }
  }

  // get is loading camera url spinner from camera wall
  public updateIsLoadingCameraLiveStreamURL(event: boolean) {
    this.isLoadingCameraLiveStreamUrl = event;
  }

  // Log out from nSide|Identity
  logOut() {
    this.authService.logout();
  }
}
