import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { IFloor } from '../../../models/floor';
import { IBuilding } from '../../../models/building';
import { FloorService } from '../../../services/floor.service';
import { MatSelectChange } from '@angular/material/select';
import { DebugUtils } from '@clients-nside-io/shared/util';

@Component({
  selector: 'clients-nside-io-floor-list',
  templateUrl: './floor-list.component.html',
  styleUrls: ['./floor-list.component.scss'],
})
export class FloorListComponent implements OnInit, OnChanges {
  @Input('current-building') currentBuilding: IBuilding | null = null;
  private currentBuildingId: number = -1;
  @Input('current-floor') currentFloor: IFloor | null = null;
  private currentFloorId: number = -1;

  public floorsList: IFloor[] = [];
  public isLoading: boolean = false;

  // selected floor from the select
  public selectedFloor: IFloor | null = null;
  // output building data event emitter
  @Output() floorSelectEvent = new EventEmitter<IFloor>();

  constructor(private floorService: FloorService, private debug: DebugUtils) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentBuilding) {
      this.currentBuildingId = changes.currentBuilding.currentValue.id;
      this.loadFloorsListBelongToBuildingId(this.currentBuildingId);
    }
  }

  private loadFloorsListBelongToBuildingId(buildingId: number) {
    this.isLoading = true;

    this.floorService.getAllFloorsBelongToBuildingId(buildingId).subscribe(
      (response) => {
        if (response.code == 200 && response.data) {
          this.floorsList = response.data;
          
          if (this.currentFloor) {
            for (var i = 0; i < this.floorsList.length; i++) {
              if (this.floorsList[i].id == this.currentFloor.id) {
                this.selectedFloor = this.floorsList[i];
              }
            }
          }
          
          this.isLoading = false;
        }
      },
      (err) => {
        this.debug.log(err);
      }
    );
  }

  public onSelectedFloorChange(event: MatSelectChange): void {
    if (event.value) {
      this.floorSelectEvent.emit(event.value);
    }
  }
}
