import { FilterModel } from './FilterModel';

export interface NotifyItem {
  data: TranscriptItem[];
  _links: PageLinks;
  description: string;
  pagingInfo: PagingInfo;
  statusCode: number;
  filterInfo: FilterModel;
}

export interface TranscriptItem {
  data: TranscriptRecord;
  _links: VoiceLinks;
}

export interface PagingInfo {
  pageNumber: number;
  pageSize: number;
  totalRecords: number;
  startId: number;
  endId: number;
  startNum: number;
  endNum: number;
}

export interface TranscriptDetailRecord {
  id: string;
  itemId: string;
  source: string;
  index: string;
  confidence: string;
  text: string;
}

export interface Transcript {
  statusCode: number;
  description: string;
  data: TranscriptDetailRecord;
}

export interface BuildingPoint {
  type: string;
  coordinates: number[];
}

export interface TranscriptRecord {
  transcript: Transcript;
  buildingId: string;
  category: string;
  categoryConfidence: string;
  subCategory: string;
  recordingUrl: string;
  buildingName: string;
  buildingPoint: BuildingPoint;
  id: string;
  type: string;
  status: string;
  received: Date;
}

export interface Detail {
  href: string;
  method: string;
}

export interface VoiceLinks {
  detail: Detail;
}

export interface PageLinks {
  firstPage: Detail;
  lastPage: Detail;
  nextPage?: Detail;
  prevPage?: Detail;
  self: Detail;
}
