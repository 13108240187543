<div class="">
  <div *ngIf="!isLoading">
    <div *ngIf="camerasList.length > 0">
      <button
        [class]="
          isSelectedAll ? 'btn btn-warning w-100' : 'btn btn-primary w-100'
        "
        (click)="allButtonHandler()"
      >
        <b>{{ isSelectedAll ? 'Deselect All' : 'Select All' }}</b>
      </button>
      <mat-selection-list
        class="mt-1"
        style="overflow: auto; max-height: 25vh"
        #camera
        [disabled]="isLoadingCameraLivestreamUrl"
      >
        <mat-list-option
          *ngFor="let camera of camerasList"
          class="camera-list-item"
          [value]="camera"
          [selected]="camera.isActive"
          (click)="getCurrentSelectedCameras()"
        >
          <span>{{ camera.platform_camera_name ? camera.platform_camera_name : camera.detail_info.camera_name }}</span>
        </mat-list-option>
      </mat-selection-list>
    </div>
    <div *ngIf="camerasList.length === 0">
      <p>There is no camera at this floor.</p>
    </div>
  </div>
  <div *ngIf="isLoading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</div>
