import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '@clients-nside-io/shared/auth';
import { map, pluck, take } from 'rxjs/operators';

@Component({
  selector: 'clients-nside-io-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'nSide|Live';

  constructor(private authService: AuthorizationService) {}

  // set up nSide|Identity
  loggedIn$: any = this.authService.loggedIn$;
  loading$: any = this.authService.loading$;
  username$ = this.authService.userName$;
  userName$: any = this.authService.user$
  .pipe(
    pluck('user_data'),
    map((el: any) => {
      JSON.parse(
        el.filter((item: any) => item.toLowerCase().includes('nsideid'))[0]
      );
    }),
    pluck('Value'),
    take(1)
  );

  ngOnInit(): void {}
}
