import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { IAPIResponse } from 'apps/live/src/app/models/api-response';
import { ICamera } from '../../../../models/camera';
import { CameraService } from '../../../../services/camera.service';

@Component({
  selector: 'clients-nside-io-camera-detail',
  templateUrl: './camera-detail.component.html',
  styleUrls: ['./camera-detail.component.scss'],
})
export class CameraDetailComponent implements OnInit {
  public targetCameraLiveStreamURL: string = "";

  constructor(
    public dialogRef: MatDialogRef<CameraDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public targetCamera: ICamera,
    private cameraService: CameraService
  ) {}

  ngOnInit(): void {
    let cameraProvider = this.targetCamera.detail_info.provider_name.toLowerCase();

    if (cameraProvider == 'rhombus') {
      this.cameraService
        .getCameraLiveStreamURLByCameraSerialNumber(this.targetCamera.detail_info.camera_sn)
        .subscribe(
          (res: IAPIResponse<string>) => {
            this.targetCameraLiveStreamURL = res.data;
          },
          (err) => {
            console.log(err);
          }
        )
    } else {
      this.targetCameraLiveStreamURL = this.targetCamera.detail_info.livestream_url;
    }
  }

}
