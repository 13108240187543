import { Injectable } from '@angular/core';
import { IBuildingAndFloor } from '../models/building-and-floor';
import { IAPIResponse } from '../models/api-response';
import { ApiRoutes } from '../models/api-routes';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BuildingAndFloorService {
  constructor(private http: HttpClient) {}

  // get the target building and target floor from input buildingId and floorId
  public getTargetBuildingAndFloor(
    buildingId: number,
    floorId: number
  ): Observable<IAPIResponse<IBuildingAndFloor>> {
    return this.http.get<IAPIResponse<IBuildingAndFloor>>(
      ApiRoutes.targetBuildingAndFloor(buildingId, floorId)
    );
  }
}
