import { OpenIdConfiguration } from 'angular-auth-oidc-client';

export interface IEnvironment {
  production: boolean;
  debugging: boolean;
  platformBaseUrl: string;
  platformDefaultUrl: string;
  identityConfig?: OpenIdConfiguration;
  [propertyName: string]: any;
}
