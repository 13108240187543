import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Pipe,
  PipeTransform,
  Output,
  EventEmitter,
} from '@angular/core';
import { ICamera } from '../../../models/camera';
import { DomSanitizer } from '@angular/platform-browser';
import { CameraService } from '../../../services/camera.service';
import { IAPIResponse } from '../../../models/api-response';
import { MatDialog } from '@angular/material/dialog'
import { CameraDetailComponent } from './camera-detail/camera-detail.component';
import { DebugUtils } from '@clients-nside-io/shared/util';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'clients-nside-io-camera-wall',
  templateUrl: './camera-wall.component.html',
  styleUrls: ['./camera-wall.component.scss'],
})
export class CameraWallComponent implements OnInit, OnChanges {
  // input current selected camera from MainComponent (or MapComponent)
  @Input('current-selected-camera-list-from-map') currentSelectedCamerasList:
    | ICamera[]
    | null = null;

  public displayedCameraList: ICamera[] = [];

  // loading spinner for the cameras list loading
  public isLoadingCameraLiveStreamURLs: boolean = false;
  // output loading spinner for the cameras list loading (support for locking camera list)
  @Output() isLoadingCameraLiveStreamURLsEvent = new EventEmitter<boolean>();

  // output active camera list event EventEmitter
  @Output() selectedCamerasListEvent = new EventEmitter<ICamera[]>();

  constructor(
    private sanitizer: DomSanitizer,
    private cameraService: CameraService,
    private dialogService: MatDialog,
    private debug: DebugUtils
  ) {}

  ngOnInit(): void {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentSelectedCamerasList) {
  

      let currentCameraChanges: ICamera[] =
        changes.currentSelectedCamerasList.currentValue;

      

      // add cameras, which did not display before.
      for (let camera of currentCameraChanges) {
        if (
          this.isInsideCameraList(camera, this.displayedCameraList) == false
        ) {

          // show up loading spinner
          this.isLoadingCameraLiveStreamURLs = true;
          this.isLoadingCameraLiveStreamURLsEvent.emit(this.isLoadingCameraLiveStreamURLs);

          // update livestream url to the camera
          // then push to camera list
          this.cameraService
            .getCameraLiveStreamURLByCameraSerialNumber(
              camera.detail_info.camera_sn
            )
            .subscribe(
              (res: IAPIResponse<string>) => {
                
                // if the url is came from Eagle Eye, need to have some justify
                if (res.data.includes('eagleeyenetworks'))
                  camera.detail_info.livestream_url = res.data;
                else camera.detail_info.livestream_url = res.data;

                if (!this.isInsideCameraList(camera, this.displayedCameraList))
                  this.displayedCameraList.unshift(camera);

                if (camera.detail_info.camera_id === currentCameraChanges[currentCameraChanges.length - 1].detail_info.camera_id) {
                  this.isLoadingCameraLiveStreamURLs = false;
                  this.isLoadingCameraLiveStreamURLsEvent.emit(this.isLoadingCameraLiveStreamURLs);
                }
              },
              (err) => {
                this.debug.log(err);
              }
            );
        }
      }

      // delete cameras, which wont display.
      let willBeDeletedCameraSN: string[] = [];
      for (let displayedCamera of this.displayedCameraList) {
        if (
          this.isInsideCameraList(displayedCamera, currentCameraChanges) ==
          false
        ) {
          willBeDeletedCameraSN.push(displayedCamera.detail_info.camera_sn);
        }
      }

      for (let cameraSN of willBeDeletedCameraSN) {
        for (let i = 0; i < this.displayedCameraList.length; i++) {
          if (cameraSN == this.displayedCameraList[i].detail_info.camera_sn) {
            this.displayedCameraList.splice(i, 1);
            break;
          }
        }
      }
    }
  }

  public isInsideCameraList(
    targetCamera: ICamera,
    camerasList: ICamera[]
  ): boolean {
    for (let camera of camerasList) {
      if (camera.detail_info.camera_sn === targetCamera.detail_info.camera_sn)
        return true;
    }
    return false;
  }

  public sanitizeLiveStreamURL(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  public openCameraDetailClickHandler(targetCamera: ICamera) {

    this.dialogService.open(CameraDetailComponent, {
      width: '80%',
      data: targetCamera
    });
  }

  public ejectCameraByIndexOnVideoWallArray(index: number) {
    if (this.displayedCameraList) {
      this.displayedCameraList?.splice(index, 1);
      this.selectedCamerasListEvent.emit(this.displayedCameraList);
    }
  }
}


