<div
  [hidden]="!currentFloor"
  class="mb-3 border border-success border-2 rounded"
>
  <google-map
    width="100%"
    height="40vh"
    [center]="center"
    [options]="options"
    [zoom]="20"
    #map
  >
    <div *ngFor="let room of rooms">
      <map-polygon
        [paths]="room.roomPolygon"
        [options]="{
          fillColor: room.fillColor ? room.fillColor : '#FABF90',
          fillOpacity: 0.8,
          strokeOpacity: 1,
          strokeWeight: 0.5,
          zIndex: 80
        }"
      ></map-polygon>

      <map-marker
        *ngIf="room.roomName"
        [options]="roomNameLabelOptions"
        [position]="{
          lat:
            (room.roomPolygonMaxMinCoor.maxLat +
              room.roomPolygonMaxMinCoor.minLat) /
            2,
          lng:
            (room.roomPolygonMaxMinCoor.maxLng +
              room.roomPolygonMaxMinCoor.minLng) /
            2
        }"
        [label]="{
          text: room.roomName,
          fontSize: '10px'
        }"
      >
      </map-marker>
    </div>

    <div *ngFor="let camera of currentCamerasList; let i = index">
      <map-polygon
        *ngIf="camera.direction_polygon"
        [paths]="camera.direction_polygon"
        [options]="{
          fillColor: '#57caff',
          fillOpacity: 0.5,
          strokeOpacity: 0.5,
          strokeWeight: 0.2,
          zIndex: 100
        }"
      ></map-polygon>
    </div>

    <map-marker *ngFor="let camera of currentCamerasList; let i = index"
        [position]="camera.geo_data[0]"
        [options]="deactiveCameraIconOptions"
        (mapClick)="deactiveCameraClickHandler(camera, i)"
      >
    </map-marker>

    <map-marker
      *ngFor="let activeCamera of activeCamerasList; let i = index"
      [position]="activeCamera.geo_data[0]"
      [options]="activeCameraIconOptions"
      (mapClick)="activeCameraClickHandler(activeCamera, i)"
    >
    </map-marker>
  </google-map>
</div>
