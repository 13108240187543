<div class="">
  <div *ngIf="!isLoading">
    <mat-form-field
      id="buildings-list"
      appearance="fill"
      style="width: 100%"
      *ngIf="buildingsList"
    >
      <mat-label>Selected a building...</mat-label>
      <mat-select
        [(value)]="selectedBuilding"
        (selectionChange)="onSelectedBuildingChange($event)"
        [formControl]="buildingCtrl"
        #buildingSelect
      >
        <mat-option>
          <ngx-mat-select-search
            placeholderLabel="Search"
            [formControl]="buildingFilterCtrl"
          >
            <span ngxMatSelectNoEntriesFound> No match building. </span>
            <mat-icon ngxMatSelectSearchClear>delete</mat-icon>
          </ngx-mat-select-search>
        </mat-option>
  
        <mat-option
          *ngFor="let building of filteredBuildings | async"
          [value]="building"
        >
          <p>{{ building.name }}</p>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="isLoading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</div>

