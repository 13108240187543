import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  DoCheck,
} from '@angular/core';
import { IFloor } from '../../../models/floor';
import { IBuilding } from '../../../models/building';
import { ICamera } from '../../../models/camera';
import { CameraService } from '../../../services/camera.service';
import { MatSelectionList } from '@angular/material/list/selection-list';

@Component({
  selector: 'clients-nside-io-camera-list',
  templateUrl: './camera-list.component.html',
  styleUrls: ['./camera-list.component.scss'],
})
export class CameraListComponent implements OnInit, OnChanges, DoCheck {
  // input current building and floor
  @Input('current-building') currentBuilding: IBuilding | null = null;
  @Input('current-floor') currentFloor: IFloor | null = null;
  private currentBuildingId: number = -1;
  private currentFloorId: number = -1;
  // for loading spinner
  public isLoading: boolean = false;
  // camera list
  public camerasList: ICamera[] = [];
  // output cameras list event emitter
  @Output() camerasListLoadedEvent = new EventEmitter<ICamera[]>();
  // list ref
  @ViewChild('camera') cameraSelectionListRef: MatSelectionList | undefined;
  // check if is selectedAll
  public isSelectedAll: boolean = false;
  // output active camera list event EventEmitter
  @Output() selectedCamerasListEvent = new EventEmitter<ICamera[]>();

  // input current selected camera from MainComponent (or MapComponent)
  @Input('current-selected-camera-list-from-map') currentSelectedCamerasList:
    | ICamera[]
    | null = null;

  @Input('is-loading-camera-livestream-url') isLoadingCameraLivestreamUrl: boolean = false;

  constructor(private cameraService: CameraService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentBuilding) {
      this.currentBuildingId = changes.currentBuilding.currentValue.id;
    }

    if (changes.currentFloor) {
      this.selectedCamerasListEvent.emit([]);
      this.currentFloorId = changes.currentFloor.currentValue.id;
      this.loadCamerasListFromBuildingFloor(
        this.currentBuildingId,
        this.currentFloorId
      );
    }

    if (changes.currentSelectedCamerasList) {
      this.setCurrentSelectedCameras(this.currentSelectedCamerasList);

      // check "Selected all" goes here
      let isSelectedAllConfirm = true;

      if (this.camerasList.length > 0) {
        for (let camera of this.camerasList)
          if (!camera.isActive) {
            isSelectedAllConfirm = false;
            break;
          }
        if (isSelectedAllConfirm == true) this.isSelectedAll = true;
        else this.isSelectedAll = false;
      }
    }
  }

  ngDoCheck(): void {}

  public loadCamerasListFromBuildingFloor(buildingId: number, floorId: number) {
    // show up the loading spinner
    this.isLoading = true;

    this.cameraService
      .getAllCamerasBelongToBuildingFloor(buildingId, floorId)
      .subscribe(
        (response) => {
          if (response.code == 200 && response.data) {
            // load cameras list, in current building's floor, from API
            this.camerasList = response.data;
            // remove the loading spinner
            this.isLoading = false;
            // send original camera list to main component
            this.camerasListLoadedEvent.emit(this.camerasList);
          } else {
            alert("We have no camera information from your building and floor now!");
            this.isLoading = false;
          }
        },
        (err) => {
          this.isLoading = false;
          this.camerasList = [];
          this.camerasListLoadedEvent.emit(this.camerasList);
        }
      );
  }

  public allButtonHandler(): void {
    if (this.isSelectedAll) {
      this.cameraSelectionListRef?.deselectAll();
      this.isSelectedAll = false;
    } else {
      this.cameraSelectionListRef?.selectAll();
      this.isSelectedAll = true;
    }

    this.getCurrentSelectedCameras();
  }

  public getCurrentSelectedCameras(): void {
    let selectedCameraList: ICamera[] = [];
    const selectedOptions =
      this.cameraSelectionListRef?.selectedOptions.selected;
    if (selectedOptions) {
      for (let option of selectedOptions) {
        selectedCameraList.push(option.value);
      }
    }

    // send the selected cameras list to main component
    this.selectedCamerasListEvent.emit(selectedCameraList);
  }

  public setCurrentSelectedCameras(
    selectedCamerasListFromMap: ICamera[] | null
  ): void {
    if (selectedCamerasListFromMap) {
      for (let camera of this.camerasList) {
        camera.isActive = false;
        for (let selectedCamera of selectedCamerasListFromMap) {
          if (
            camera.detail_info.camera_sn == selectedCamera.detail_info.camera_sn
          ) {
            camera.isActive = true;
            break;
          }
        }
      }
    }
  }
}
